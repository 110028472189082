import {defineStore} from 'pinia';

function searchMap(map, id) {
  let foundItem = null;

  map.forEach((value) => {
    const item = value.find(element => element.id === id);
    if (item) {
      foundItem = item;
    }
  });

  return foundItem;
}

export const makeAlbumsStore = (id = 'albumsStore') => defineStore(id, {
  state: () => ({
    albums: null,
    hasMore: false,
    cursor: undefined,
    collectionDescriptor: null,
    album: null,

    nestedAlbums: new Map()
  }),

  actions: {
    async getAlbums({nextPage, userId, search, parentAlbumId, shareToken, privacy, privacyFilter, sharedOnly, root, order} = {}) {
      if (!nextPage) {
        this.clearAlbums();
        this.collectionDescriptor = {userId, search, parentAlbumId, shareToken, privacy, privacyFilter, sharedOnly, root, order};
      }

      const method = this.collectionDescriptor.sharedOnly ? 'getSharedAlbums' : 'getAlbums';
      const data = await useAlbumApi()[method]({
        cursor: this.cursor,
        ...this.collectionDescriptor
      });

      this.albums = [...(this.albums || []), ...data.albums];
      this.hasMore = data.albums.length === data.per_page;
      this.cursor = data.next_cursor;

      return this.albums;
    },

    async getNestedAlbums({userId, parentAlbumId, shareToken}) {
      const albums = await fetchAllRecords('albums', cursor => useAlbumApi().getAlbums(
        {cursor, userId, parentAlbumId, shareToken}
      ));

      this.nestedAlbums.set(parentAlbumId, albums);
    },

    clearAlbums() {
      this.albums = null;
      this.hasMore = false;
      this.cursor = undefined;
      this.collectionDescriptor = null;
    },

    clearNestedAlbums() {
      this.nestedAlbums.clear();
    },

    async getAlbumsOne({id, userId, shareToken}) {
      const album = await useAlbumApi().getAlbum({id, userId, shareToken});
      const updateIndex = this.albums?.findIndex(item => item.id === album.id);

      if (updateIndex > -1) {
        this.albums.splice(updateIndex, 1, album);
      }

      let nestedAlbum = searchMap(this.nestedAlbums, album.id)
      if (nestedAlbum) {
        nestedAlbum = Object.assign(nestedAlbum, album);
      }

      return album;
    },

    async removeAlbumsOne({id}) {
      const i = this.albums.findIndex(item => (id === item.id));
      this.albums.splice(i, 1);
    },

    async getAlbum({id, userId, shareToken, addedSince}) {
      const albumApi = useAlbumApi();

      const [album, count] = await Promise.all([
        albumApi.getAlbum({id, userId, shareToken}),
        addedSince ? albumApi.getAlbumFilesAddedSince({id, userId, addedSince}) : null
      ]);

      if (addedSince) {
        album.files_added = count;
        album.addedSince = addedSince;
      }

      album.user.isStorageFull = album.user.storage_used >= album.user.storage_capacity;
      album.isOwnedByUser = useUserContext({entity: album}).isEntityOwner.value;

      if (this.album?.id === id) {
        Object.assign(this.album, album);
      } else {
        this.album = album;
      }

      return this.album;
    },

    async updateAlbum({id, updates}) {
      const updatedAlbum = await useAlbumApi().updateAlbum({albumId: id, payload: updates});

      if (this.album && id === this.album.id) {
        Object.assign(this.album, updatedAlbum);
      }

      const listAlbum = this.albums?.find(a => a.id === id);
      if (listAlbum) {
        Object.assign(listAlbum, updatedAlbum);
      }

      let nestedAlbum = searchMap(this.nestedAlbums, updatedAlbum.id)
      if (nestedAlbum) {
        nestedAlbum = Object.assign(nestedAlbum, updatedAlbum);
      }

      return updatedAlbum;
    },

    async createAlbum({payload}) {
      const album = await useAlbumApi().createAlbum(payload);
      const parentId = album.parent_album?.id;

      if (this.nestedAlbums.has(parentId)) {
        this.nestedAlbums.get(parentId).push(album);
      }

      if (this.album && this.album.id === parentId) {
        this.album.albums_count = this.album.albums_count + 1;
      }

      const parentAlbum = this.albums?.find(a => a.id === parentId) || searchMap(this.nestedAlbums, parentId)
      if (parentAlbum) {
        parentAlbum.albums_count++;
      }

      return album;
    },

    async destroyAlbums({ids}) {
      await useAlbumApi().destroyAlbums({ids});

      this.albums = this.albums?.filter(a => !ids.includes(a.id));
      if (this.album && !ids.includes(this.album.id)) {
        this.album.albums_count = this.album.albums_count - ids.length;
      }

      this.nestedAlbums.forEach((value, key) => {
        this.nestedAlbums.set(key, value.filter(a => !ids.includes(a.id)));
      });
    },

    async disableShareToken({id}) {
      await useAlbumApi().disableShareToken({id});

      if (this.album) {
        this.album.share_token = null;
        this.album.collaborative = false;
      }

      this.albums?.forEach(item => {
        if (item.id === id) {
          item.share_token = null;
          item.collaborative = false;
        }
      });

    },

    async refreshCurrentAlbum() {
      if (this.album) {
        this.album = await this.getAlbum({id: this.album.id});
      }
    },

    async resetShareToken({id}) {
      const newToken = await useAlbumApi().resetShareToken({id});

      if (this.album) {
        this.album.share_token = newToken;
      }

      this.albums.forEach(item => {
        if (item.id === id) {
          item.share_token = newToken;
        }
      });
    },

    async removeVisitedAlbums({ids}) {
      await useAlbumApi().removeVisitedAlbums({ids});
      this.albums = this.albums?.filter(a => !ids.includes(a.sharedItemId));
    }
  }
});

export const useAlbumsStore = makeAlbumsStore();
